panels();

function panels(){
    var $panels = $('.section--panel');

    function setPanelHeight(){
        var windowWidth = $(window).width();
        var desktop = windowWidth >= 845;

        if (desktop) {
            $panels.each(function(){
                var $panel = $(this);
                var panelHeight = $(window).outerHeight();

                $panel.css('min-height', panelHeight);

                $(window).on('resize', function(){
                    panelHeight = $(window).outerHeight();

                    $panel.css('min-height', panelHeight);
                });
            });
        }
    }

    setPanelHeight();
    $(window).resize(setPanelHeight);
}

home();

function home(){
    var controller = new ScrollMagic.Controller();

    var $bg2 = $('#bg2');
    var $bg3 = $('#bg3');
    var $bg4 = $('#bg4');
    var $s1h1 = $('#s1 h1');
    var $s1h2 = $('#s1 h2');
    var $s1p = $('#s1 p');
    var $arrow = $('.arrow');
    var $laptop = $('#laptop');
    var $laptopWrapper = $('#laptop-wrapper')

    TweenMax.to($s1h1, 1, {css: { opacity: 1 }, delay: 0.5 });
    TweenMax.to($s1h2, 0.5, {css:{ opacity: 1 }, delay: 1 });
    TweenMax.to($s1p, 2, {css:{ opacity: 1 }, delay: 2 });
    TweenMax.to($laptop, 1, {css:{ opacity: 1, top: 0 }, delay: 1.5 });
    TweenMax.to($arrow, 2, {css:{ opacity: 1 }, delay: 2 });

    var bg2Scene = new ScrollMagic.Scene({ triggerElement: '#bg2-trigger' }).setTween('#bg2', 0.5, {opacity: 1}).addTo(controller);
    var bg3Scene = new ScrollMagic.Scene({ triggerElement: '#bg3-trigger' }).setTween('#bg3', 0.5, {opacity: 1}).addTo(controller);
    var bg4Scene = new ScrollMagic.Scene({ triggerElement: '#bg4-trigger' }).setTween('#bg4', 0.5, {opacity: 1}).addTo(controller);
}